/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.container__add-new-organization {
  display: flex;
  flex-direction: column;
  min-height: calc(49vh - 80px);
  font-size: 18px;
}
/*------Контейнер для основного блока с контентом---------------------------------------------------------------------*/
.add-new-organization {
  display: flex;
  flex-direction: column;
  margin-bottom: 6.2em;
  background-color: #ffffff;
  box-shadow: -3px 0 8px rgba(54, 58, 72, 0.1);
  border-radius: 0.8em;
  padding: 1.77em;
}
/*------Контейнер для блока с инпутом названия организации------------------------------------------------------------*/
.add-new-organization__name-org-input {
  display: flex;
  flex-direction: column;
  gap: 0.88em;
  margin-bottom: 1.77em;
}
.name-org-input__label-name-org {
  line-height: 110%;
  font-weight: 400;
  color: #363b4d;
  opacity: 90%;
}
.name-org-input__field {
  line-height: 110%;
  height: 2.66em;
  outline: none;
  border: 0.5px solid rgba(54, 59, 77, 0.2);
  border-radius: 0.44em;
  padding: 0.66em 1.33em 0.66em 1.33em;
  color: #363b4d;
  opacity: 90%;
  font-size: 1em;
}
.name-org-input__field::placeholder {
  color: #363b4d;
  opacity: 40%;
}
.name-org-input__red-star {
  color: #ff4970;
}
/*------Контейнер для блока с кнопками импорт excel и добавить e-mail-------------------------------------------------*/
.add-new-organization__import-excel-add-email-buttons {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.77em;
  gap: 0.88em;
  align-items: center;
}
.import-excel-add-email-buttons__import-excel-btn {
  display: flex;
  flex-direction: row;
  gap: 0.66em;
  align-items: center;
  font-size: 0.88em;
  color: #4ed4a9;
  border: 1px solid #4ed4a9;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.66em 1.66em 0.66em 1.66em;
  height: 2.66em;
}
.import-excel-add-email-buttons__import-excel-btn.active {
  background-color: #4ed4a9;
  color: #ffffff;
}
.import-excel-btn__excel-icon {
  width: 1.33em;
  height: 1.33em;
}
.import-excel-add-email-buttons__add-email-btn {
  display: flex;
  flex-direction: row;
  gap: 0.66em;
  align-items: center;
  font-size: 0.88em;
  color: #0084fe;
  border-radius: 4px;
  cursor: pointer;
  padding: 0.66em 1.66em 0.66em 1.66em;
  border: 1px solid#0084fe;
  height: 2.66em;
}
.import-excel-add-email-buttons__add-email-btn.active {
  background-color: #0084fe;
  color: #ffffff;
}
.add-email-btn__email-icon {
  width: 1.33em;
  height: 0.88em;
}
.add-email-btn__button-mobile {
  display: none;
}
/*------Контейнер для блока с почтовыми адресами пользователей--------------------------------------------------------*/
.add-new-organization__mail-addresses-users {
  display: flex;
  flex-direction: column;
  gap: 0.88em;
  margin-bottom: 1.77em;
}
.mail-addresses-users__label-mail {
  font-size: 1em;
  color: #363b4d;
  opacity: 90%;
}
.mail-addresses-users__input-field {
  font-family: "Inter";
  outline: none;
  font-size: 1em;
  color: #363b4d;
  border: 0.5px solid rgba(54, 59, 77, 0.2);
  border-radius: 0.44em;
  line-height: 110%;
  opacity: 90%;
  padding: 0.88em 1.33em 0.88em 1.33em;
  height: 7.11em;
  resize: none;
}
.mail-addresses-users__search-base-users {
  font-size: 0.88em;
  line-height: 124%;
  cursor: pointer;
  color: #0084fe;
  background-color: #ffffff;
  width: 17em;
  height: 2.66em;
  border: 1px solid #0084fe;
  border-radius: 4px;
}
.mail-addresses-users__search-base-users:hover {
  background-color: #0084fe;
  color: #ffffff;
}

.input_container {
  border: 1px solid #e5e5e5;
  width: 100%;
}
/*------Контейнер для блока с загрузкой файла-------------------------------------------------------------------------*/
.add-new-organization__download-file-load-button {
  display: flex;
  flex-direction: column;
  gap: 0.88em;
  margin: 0 auto 1.77em 0;
}
.download-file-load-button__label {
  font-size: 1em;
  line-height: 133%;
  color: #363b4d;
  opacity: 90%;
}
.add-new-organization__excel-add-container {
  display: flex;
  position: relative;
  margin: 0 auto 0 0;
}
.add-new-organization__excel-add-input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
.add-new-organization__excel-add-input-container {
  display: flex;
  cursor: pointer;
  margin: 0 24px 0 0;
}
.add-new-organization__excel-add-input-file-name-container {
  display: flex;
  width: 580px;
  height: 48px;
  background: #ffffff;
  border: 0.5px solid rgba(54, 59, 77, 0.6);
  border-right: 0;
  border-radius: 8px 0 0 8px;
}
.add-new-organization__excel-add-input-file-name-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: rgba(54, 59, 77, 0.4);
  opacity: 0.9;
  margin: auto auto auto 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-new-organization__excel-add-input-file-name-text_selected {
  color: rgba(54, 59, 77, 1);
}
.add-new-organization__excel-add-input-button {
  display: flex;
  width: 129px;
  height: 48px;
  background: #ffffff;
  border: 0.5px solid#0084fe;
  border-radius: 0px 8px 8px 0px;
}
.add-new-organization__excel-add-input-button-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0084fe;
  opacity: 0.9;
  margin: auto;
}
.field-file-load-button__success-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.33em;
}
.field-file-load-button__success-info.hide {
  display: none;
}
.field-file-load-button__icon-success {
  width: 1em;
  height: 1em;
}
.field-file-load-button__label {
  color: #4ed4a9;
  line-height: 200%;
  font-size: 1em;
}
.field-file-load-button__label-error {
  color: #FF4970;
  line-height: 200%;
  font-size: 1em;
}
/*------Контейнер для верхней пагинации-------------------------------------------------------------------------------*/
.add-new-organization__top-pagination {
  /*margin-bottom: 0.88em;*/
  z-index: 1;
}
/*------Контейнер для таблицы c пользователями------------------------------------------------------------------------*/
.add-new-organization__table-list-users {
  display: flex;
  flex-direction: column;
  border-radius: 1.33em;
  border: 0.5px solid rgba(54, 59, 77, 0.2);
  /*margin-bottom: 0.88em;*/
}
.table-list-users {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1.33em;
  border-top: 0.5px solid rgba(54, 59, 77, 0.2);
}
.table-list-users__no-users-container {
  display: flex;
  border-radius: 8px;
  border: 0.5px solid rgba(54, 59, 77, 0.2);
  margin-bottom: 0.88em;
  width: 100%;
  height: 48px;
}
.table-list-users__no-users {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: rgba(54, 59, 77, 1);
  opacity: 0.9;
  margin: auto;
}
.add-new-organization__table-list-users div:first-child {
  border-top: none;
}
.table-list-users__name-user-icon-lock {
  display: flex;
  gap: 12px;
  align-items: center;
  /*width: 21.3em;*/
  width: 100%;
}
.table-list-users__name-user-icon-lock-add-group {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
}
.table-list-users__column-name {
  width: 100%;
  line-height: 129%;
  color: #363b4d;
  opacity: 90%;
  min-height: 3.55em;
  display: flex;
  align-items: center;
  border-right: 0.5px solid rgba(54, 59, 77, 0.2);
  word-break: break-word;
  white-space: break-spaces;
  margin-right: 2.38em;
  padding-right: 1.77em;
}
.table-list-users__column-e-mail {
  /*width: 23.55em;*/
  width: 100%;
  line-height: 129%;
  color: #363b4d;
  opacity: 90%;
  min-height: 3.55em;
  display: flex;
  align-items: center;
  border-right: 0.5px solid rgba(54, 59, 77, 0.2);
  word-break: break-word;
  white-space: break-spaces;
  margin-right: 2.38em;
  padding-right: 2.3em;
}
.table-list-users__column-e-mail-add-group {
  width: 100%;
  line-height: 129%;
  color: #363b4d;
  opacity: 90%;
  min-height: 3.55em;
  display: flex;
  align-items: center;
  border-right: 0.5px solid rgba(54, 59, 77, 0.2);
  word-break: break-word;
  white-space: break-spaces;
  margin-right: 2.38em;
  padding-right: 2.3em;
}
.table-list-users__column-checkbox-superuser {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.88em;
  /*width: 15.72em;*/
  width: 100%;
  min-height: 3.55em;
  border-right: 0.5px solid rgba(54, 59, 77, 0.2);
  margin-right: 2em;
  padding-right: 1.9em;
}
.column-checkbox-superuser__label {
  font-size: 0.88em;
  line-height: 124%;
  color: #363b4d;
  opacity: 90%;
}
.table-list-users__checkbox_container {
  position: relative;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
}
.table-list-users__checkbox_container input {
  position: absolute;
  opacity: 0;
}
.table-list-users__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.1em;
  width: 1.1em;
  background-color: #ffffff;
  border-color: #0084fe;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
}
.table-list-users__checkbox_container:hover
  input
  ~ .table-list-users__checkmark {
  background-color: #ffffff;
}
.table-list-users__checkbox_container
  input:checked
  ~ .table-list-users__checkmark {
  background-color: #0084fe;
}
.table-list-users__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.table-list-users__checkbox_container
  input:checked
  ~ .table-list-users__checkmark:after {
  display: block;
}
.table-list-users__checkbox_container .table-list-users__checkmark:after {
  left: 0.3em;
  top: 0.2em;
  width: 0.4em;
  height: 0.4em;
  border: solid white;
  border-width: 0 1px 1px 0;
  /*-webkit-transform: rotate(45deg);*/
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.table-list-users__delete-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.72em;
  margin: auto;
  cursor: pointer;
  width: 100%;
}
.delete-icon-button__delete-btn {
  color: #ff4970;
  line-height: 134%;
  font-size: 0.88em;
}
.delete-icon-button__icon-delete {
  width: 0.77em;
  height: 0.77em;
}
.delete-icon-button__delete-btn-mobile {
  display: none;
}
/*------Контейнер для нижней пагинации--------------------------------------------------------------------------------*/
.add-new-organization__bottom-pagination {
  /*margin-bottom: 1.77em;*/
  z-index: 1;
}
/*------Контейнер инпута с e-mail поддержки---------------------------------------------------------------------------*/
.add-new-organization__e-mail-support {
  display: flex;
  flex-direction: column;
  gap: 0.88em;
  margin-bottom: 1.77em;
}
.e-mail-support__e-mail-label {
  line-height: 110%;
  font-weight: 400;
  color: #363b4d;
  opacity: 90%;
}
.e-mail-support__field {
  line-height: 110%;
  height: 2.66em;
  outline: none;
  border: 0.5px solid rgba(54, 59, 77, 0.2);
  border-radius: 0.44em;
  padding: 0.66em 1.33em 0.66em 1.33em;
  color: #363b4d;
  opacity: 90%;
  font-size: 1em;
}
.e-mail-support__field::placeholder {
  color: #363b4d;
  opacity: 40%;
}
/*------Контейнер инпута с примечаниями-------------------------------------------------------------------------------*/
.add-new-organization__note-input {
  display: flex;
  flex-direction: column;
  gap: 0.88em;
  margin-bottom: 10px;
}
.note-input__note-label {
  line-height: 110%;
  font-weight: 400;
  color: #363b4d;
  opacity: 90%;
}
.note-input__field {
  font-family: "Inter";
  line-height: 110%;
  height: 7.11em;
  outline: none;
  border: 0.5px solid rgba(54, 59, 77, 0.2);
  border-radius: 0.44em;
  padding: 0.88em 1.33em 0.88em 1.33em;
  color: #363b4d;
  opacity: 90%;
  font-size: 1em;
  resize: none;
}
.note-input__field::placeholder {
  color: #363b4d;
  opacity: 40%;
}
/*------Контейнер для кнопки сохранить--------------------------------------------------------------------------------*/
.save-button__add-new-org-save-btn {
  border-radius: 4px;
  background-color: #0084fe;
  width: 15em;
  height: 3.11em;
  color: #ffffff;
  line-height: 110%;
  font-weight: 500;
  cursor: pointer;
  font-size: 1em;
  margin: 10px auto 0 0;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:disabled {
    background-color: darkgray;
    &:hover {
        background-color: darkgray;
        border-color: darkgray;
        color: white;
        cursor: unset;
    }
  }
}
.save-button__add-new-org-save-btn.active {
  background: darkgray;
  pointer-events: none;
  border: none;
}
.save-button__add-new-org-save-btn:hover {
  background-color: #ffffff;
  color: #0084fe;
  border: 1px solid#0084fe;
}
.add-new-organization__error-message {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ff4970;
  opacity: 0.9;
  margin: 0 auto 0 0;
}
/*-----Адаптивная версия(под разные устройства)-----------------------------------------------------------------------*/
@media (max-width: 1570px) {
  .table-list-users__name-user-icon-lock-add-group {
    /*width: 240px;*/
  }
  .table-list-users__column-name {
    /*width: 240px;*/
    font-size: 16px;
    margin-right: 8px;
    padding-right: 8px;
  }
  .table-list-users__column-e-mail-add-group {
    /*width: 240px;*/
    font-size: 16px;
    margin-right: 8px;
    padding-right: 8px;
  }
  .table-list-users__column-checkbox-superuser {
    width: 200px;
    /*min-height: 100%;*/
    gap: 8px;
    font-size: 16px;
    margin-right: 8px;
    padding-right: 8px;
  }
  .table-list-users__delete-icon-button {
    font-size: 16px;
  }
  .container__add-new-organization {
    font-size: 16px;
    min-height: calc(65vh - 80px);
  }
  .add-new-organization__excel-add-input-file-name-container {
    width: 498px;
  }
  .import-excel-add-email-buttons__import-excel-btn {
    font-size: 14px;
  }
  .import-excel-add-email-buttons__add-email-btn {
    font-size: 14px;
  }
  .add-new-organization__mail-addresses-users {
    gap: 8px;
  }
  .mail-addresses-users__label-mail {
    font-size: 14px;
  }
  .mail-addresses-users__search-base-users {
    font-size: 14px;
  }
  .mail-addresses-users__input-field {
    font-size: 14px;
  }
}
@media (max-width: 1350px) {
  .container__add-new-organization {
    font-size: 12px;
    min-height: calc(65vh - 80px);
  }
  .add-new-organization__excel-add-input-container {
    margin: 0 14px 0 0;
  }
  .add-new-organization__excel-add-input-file-name-container {
    width: 350px;
    height: 35px;
  }
  .add-new-organization__excel-add-input-file-name-text {
    font-size: 14px;
    line-height: 18px;
    margin: auto auto auto 18px;
  }
  .add-new-organization__excel-add-input-button {
    width: 120px;
    height: 35px;
  }
  .add-new-organization__excel-add-input-button-text {
    font-size: 12px;
    line-height: 18px;
  }
  .table-list-users__no-users-container {
    height: 30px;
  }
  .table-list-users__no-users {
    font-size: 12px;
    line-height: 18px;
  }
  .add-new-organization__error-message {
    font-size: 12px;
    line-height: 18px;
  }
}
@media (max-width: 830px) {
  .container__add-new-organization {
    font-size: 10px;
    min-height: calc(65vh - 80px);
  }
  .add-new-organization__excel-add-input-container {
    margin: 0 8px 0 0;
  }
  .add-new-organization__excel-add-input-file-name-container {
    width: 294px;
    height: 30px;
  }
  .add-new-organization__excel-add-input-file-name-text {
    font-size: 12px;
    line-height: 16px;
    margin: auto auto auto 16px;
  }
  .add-new-organization__excel-add-input-button {
    width: 100px;
    height: 30px;
  }
  .add-new-organization__error-message {
    font-size: 10px;
    line-height: 16px;
  }
}
@media (max-width: 600px) {
  .container__add-new-organization {
    font-size: 8px;
    min-height: calc(65vh - 80px);
  }
  .add-new-organization__excel-add-input-file-name-container {
    width: 217px;
    height: 25px;
  }
  .add-new-organization__excel-add-input-file-name-text {
    font-size: 11px;
    line-height: 14px;
    margin: auto auto auto 12px;
  }
  .add-new-organization__excel-add-input-button {
    width: 100px;
    height: 25px;
  }
  .add-new-organization__excel-add-input-button-text {
    font-size: 10px;
    line-height: 14px;
  }
  .add-new-organization__error-message {
    font-size: 8px;
    line-height: 12px;
  }
}
/*-----Адаптивная версия под мобилку----------------------------------------------------------------------------------*/
@media (max-width: 800px) {
  .container__add-new-organization {
    margin-bottom: 0;
  }
  .add-new-organization {
    padding: 16px 16px;
  }
  .add-new-organization__name-org-input {
    margin-bottom: 16px;
  }
  .name-org-input__label-name-org {
    font-size: 16px;
    line-height: 114%;
  }
  .name-org-input__field {
    font-size: 16px;
    padding: 13px 16px 13px 16px;
    line-height: 114%;
    height: 48px;
    border: 1px solid rgba(54, 59, 77, 0.4);
  }
  .add-new-organization__import-excel-add-email-buttons {
    flex-direction: column;
    margin-bottom: 16px;
    gap: 16px;
  }
  .import-excel-add-email-buttons__import-excel-btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
    padding: 12px 13px 12px 13px;
    justify-content: center;
  }
  .import-excel-add-email-buttons__add-email-btn {
    width: 100%;
    height: 48px;
    font-size: 16px;
    padding: 12px 41px 12px 39px;
    justify-content: center;
  }
  .add-email-btn__button-desktop {
    display: none;
  }
  .add-email-btn__button-mobile {
    display: initial;
  }
  .add-new-organization__mail-addresses-users {
    gap: 0;
    margin-bottom: 19px;
  }
  .mail-addresses-users__label-mail {
    font-size: 16px;
    line-height: 114%;
    margin-bottom: 8px;
  }
  .mail-addresses-users__input-field {
    margin-bottom: 16px;
    border: 1px solid rgba(54, 59, 77, 0.4);
    font-size: 18px;
    padding: 12px 12px;
  }
  .mail-addresses-users__search-base-users {
    width: 100%;
    height: 48px;
    font-size: 16px;
    line-height: 124%;
  }
  .add-new-organization__top-pagination {
    margin-bottom: 8px;
  }
  .add-new-organization__table-list-users {
    /*margin-bottom: 24px;*/
    border: none;
  }
  .table-list-users {
    flex-direction: column;
    padding: 16px 0 0 0;
    margin-bottom: 16px;
  }
  .table-list-users__no-users-container {
    height: 48px;
    margin-bottom: 1.6em;
  }
  .table-list-users__no-users {
    font-size: 16px;
    line-height: 24px;
  }
  .table-list-users__name-user-icon-lock {
    width: 100%;
    margin-bottom: 8px;
    height: 21px;
  }
  .table-list-users__name-user-icon-lock-add-group {
    width: 100%;
    margin-bottom: 8px;
    height: 21px;
  }
  .table-list-users__column-name {
    border: none;
    width: 100%;
    font-size: 16px;
    line-height: 114%;
    margin-right: 0;
    padding-right: 0;
    min-height: 0;
  }
  .table-list-users__column-e-mail {
    border: none;
    width: 100%;
    font-size: 14px;
    line-height: 114%;
    margin-right: 0;
    padding-right: 0;
    min-height: 0;
    margin-bottom: 16px;
    color: rgba(54, 59, 77, 0.4);
  }
  .table-list-users__column-e-mail-add-group {
    border: none;
    width: 100%;
    font-size: 14px;
    line-height: 114%;
    margin-right: 0;
    padding-right: 0;
    min-height: 0;
    margin-bottom: 16px;
    color: rgba(54, 59, 77, 0.4);
  }
  .table-list-users__column-checkbox-superuser {
    border: none;
    width: 100%;
    font-size: 14px;
    line-height: 114%;
    margin-right: 0;
    padding-right: 0;
    min-height: 0;
    margin-bottom: 16px;
  }
  .column-checkbox-superuser__label {
    font-size: 16px;
    margin-left: 12px;
  }
  .table-list-users__checkmark {
    width: 24px;
    height: 24px;
    top: -6px;
  }
  .table-list-users__checkbox_container .table-list-users__checkmark:after {
    width: 11px;
    height: 8px;
    top: 6px;
  }
  .table-list-users__delete-icon-button {
    width: 100%;
    display: flex;
    margin-right: 0;
    padding-right: 0;
    min-height: 0;
  }
  .delete-icon-button__delete-btn,
  .delete-icon-button__icon-delete {
    display: none;
  }
  .delete-icon-button__delete-btn-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff4970;
    width: 100%;
    height: 48px;
    font-size: 16px;
    border: 1px solid #ff4970;
    border-radius: 4px;
    cursor: pointer;
  }
  .add-new-organization__e-mail-support {
    margin-bottom: 32px;
  }
  .e-mail-support__e-mail-label {
    font-size: 16px;
    line-height: 114%;
    margin-bottom: 8px;
  }
  .e-mail-support__field {
    font-size: 16px;
    padding: 13px 16px 13px 16px;
    line-height: 114%;
    height: 48px;
    border: 1px solid rgba(54, 59, 77, 0.4);
  }
  .add-new-organization__note-input {
    margin-bottom: 16px;
  }
  .note-input__note-label {
    font-size: 16px;
    line-height: 114%;
    margin-bottom: 8px;
  }
  .note-input__field {
    font-size: 16px;
    padding: 13px 16px 13px 16px;
    line-height: 114%;
    height: 128px;
    border: 1px solid rgba(54, 59, 77, 0.4);
  }
  .save-button__add-new-org-save-btn {
    width: 100%;
    height: 56px;
    font-size: 18px;
    line-height: 110%;
    margin: 0 auto 8px 0;
  }
  .download-file-load-button__label {
    font-size: 16px;
    line-height: 138%;
    margin-bottom: 16px;
  }
  .add-new-organization__download-file-load-button {
    gap: 0;
    width: 100%;
  }
  .add-new-organization__excel-add-container {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
  .add-new-organization__excel-add-input-container {
    width: 100%;
    margin: 0;
  }
  .add-new-organization__excel-add-input-file-name-container {
    width: 70%;
    max-width: 330px;
    height: 48px;
  }
  .add-new-organization__excel-add-input-file-name-text {
    font-size: 14px;
    line-height: 18px;
    margin: auto auto auto 12px;
  }
  .add-new-organization__excel-add-input-button {
    width: 30%;
    height: 48px;
  }
  .add-new-organization__excel-add-input-button-text {
    font-size: 14px;
    line-height: 18px;
  }
  .download-file-load-button__field-file-load-button {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 48px 1fr;
    grid-template-areas:
      "blockdownload loadbtn"
      "success success";
    width: 100%;
    grid-row-gap: 16px;
  }
  .field-file-load-button__load-btn {
    grid-area: loadbtn;
    height: 48px;
    font-size: 16px;
    padding: 12px 24px 12px 24px;
    margin: 0;
  }
  .field-file-load-button__success-info {
    grid-area: success;
    gap: 6px;
  }
  .field-file-load-button__icon-success {
    width: 12px;
    height: 9px;
  }
  .field-file-load-button__label {
    font-size: 12px;
    line-height: 200%;
  }
  .field-file-load-button__label-error {
    font-size: 12px;
    line-height: 200%;
  }
  .add-new-organization__error-message {
    font-size: 12px;
    line-height: 16px;
    margin: 0 auto 12px 0;
  }
}
