.my-votes-block-wrapper h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    padding-bottom: 0;
    color: rgba(54, 59, 77, 1);
}
.my-votes-block-wrapper:last-child {
    margin-bottom: 30px;
}
/*------Стили для обоих блоков---------------------------------------------------------------------------------------*/
.my-votes-block__content-vote {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.my-votes-block__vote-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #363B4D;
    border-bottom: 1px #EEEEEE;
    border-style: solid;
    padding-bottom: 24px;
    margin-bottom: 24px;
    font-size: 16px;
}
.my-votes-block__vote-form_votes-page {
    flex-direction: row;
    display: flex;
    margin-bottom: 24px;
    font-size: 18px;
}
.my-votes-block__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.reg-form__status-block, .vote-form__status-block {
    display: flex;
    flex-direction: row;
    max-height: 56px;
    align-items: center;
    /*margin-bottom: 24px;*/
}
.my-votes-block__container-title-block {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.my-votes-block__container-title-h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 133%;
    color: rgba(54, 59, 77, 1);
    margin: 0 auto 0 0;
    cursor: pointer;
}
.my-votes-block__container-title-h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.6);
}
.my-votes-block__votes-form {
    padding-bottom: 24px;
}
.status-and-start-reg-start-vote__add-border-left {
    border-left: 1px rgba(54, 59, 77, 0.2) solid;
}
.my-votes-block__utc-container {
    display: flex;
}
.my-votes-block__utc-icon {
    margin: auto 8px auto 0;
}
.my-votes-block__utc-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(54, 59, 77, 0.6);
    margin: auto auto auto 0;
}
/*------Блок с кнопками-----------------------------------------------------------------------------------------------*/
.my-votes-block__buttons-block {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    justify-content: center;
}
.my-votes-block__show-vote-icon,
.my-votes-block__edit-icon,
.my-votes-block__delete-icon {
    width: 1em;
    height: 1em;
}
.my-votes-block__btn-block-show,
.my-votes-block__btn-block-new-tab,
.my-votes-block__btn-block-edit,
.my-votes-block__btn-block-delete {
    display: flex;
    flex-direction: row;
    gap: 0.61em;
    cursor: pointer;
}
.my-votes-block__show-label,
.my-votes-block__edit-label {
    color:#0084fe;
    font-weight: 300;
}
.my-votes-block__delete-label {
    color: #FF4970;
    font-weight: 300;
}
.my-votes-block__new-tab-label {
    color: rgba(54, 59, 77, 1);
    font-weight: 300;
}

/*------Ccылка со стрелкой ПОКАЗАТЬ ПОЛНОСТЬЮ--------------------------------------------------------------------------*/
.my-votes__link-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #0075FF;
    gap: 5px;
    margin-top: auto;
}
.link-arrow__show-all {
    font-size: 16px;
    font-weight: 300;
}
.my-votes__link-arrow a {
    color: #0075FF;
    text-decoration: none;
}
.my-votes__link-arrow img {

}
.my-votes-block__link-votes-page {
    pointer-events: none;
    text-decoration: none;
}
/*------Адаптивная версия--------------------------------------------------------------------------------------*/
@media (max-width: 1270px) {
    .my-votes-block-wrapper h2 {
        font-size: 21px;
        font-weight: 500;
        line-height: 138%;
    }
    .my-votes-block-wrapper {
        padding: 16px 16px;
    }
    .my-votes-block__content-vote {
        flex-direction: column;
    }
    .my-votes-block__buttons-block {
        flex-direction: row;
        margin-top: 1.77em;
        justify-content:flex-start;
    }
}

@media (max-width: 1180px) {
    .my-votes-block__vote-form_votes-page {
        flex-direction: column;
    }
    .votes-form__button-vote-cancel-reg_votes-page {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-start;
        margin: 0;
    }
}
@media (max-width: 830px) {
    .my-votes-block-wrapper {
        max-width: 440px;
        padding: 16px 16px 16px 16px;
    }
    .my-votes-block-wrapper h2 {
        font-size: 21px;
        line-height: 144%;
        padding-bottom: 8px;
        font-weight: 500;
    }
    .my-votes-block__votes-form {
        padding-bottom: 0;
    }
    /*.my-votes-block__vote-form h3 {*/
    /*    font-size: 14px;*/
    /*}*/
    /*.my-votes-block__vote-form h5 {*/
    /*    font-size: 13px;*/
    /*}*/
    .status-block__start-vote h5, .vote-form__status-block-start-vote h5 {
        font-size: 10px;
    }
    .reg-form__button-reg, .button-vote, .reg {
        width: 150px;
        height: 40px;
        font-size: 12px;
    }
    .cancel-reg {
        width: 200px;
        height: 40px;
        font-size: 12px;
    }
    .link-arrow__show-all {
        font-size: 14px;
    }
    .reg-form__status-block {
        max-height: 50px;
    }
    .vote-form__status-block {
        max-height: 50px;
    }
    .my-votes-block__utc-value {
        font-size: 10px;
    }
    .my-votes-block__vote-form {
        font-size: 14px;
    }
}

@media (max-width: 815px) {
    .my-votes-block-wrapper {
        max-width: 800px;
        width: 100%;
        height: 60px;
    }
    .link-arrow__show-all {
        display: none;
    }
    .my-votes__link-arrow img {
        position: absolute;
        top: 20px;
        right: 27px;
    }
    .vote-form__status-block {
        display: none;
    }
    .my-votes-block__container {
        gap: 16px;
    }
    .my-votes-block__container-title-block {
        gap: 8px;
    }
    .my-votes-block__container-title-h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
    .my-votes-block__container-title-h5 {
        font-size: 16px;
        line-height: 24px;
    }
    .my-votes-block__link-votes-page {
        pointer-events: auto;
    }
}
@media (max-width: 500px) {
    .reg-form__button-reg, .button-vote, .reg {
        width: 100%;
        height: 48px;
        font-size: 16px;
    }
    .my-votes-block__utc-container {
        display: none;
    }
    .cancel-reg {
        width: 100%;
        height: 48px;
        font-size: 16px;
    }
    .my-votes-block__vote-form_votes-page {
        margin-bottom: 16px;
    }
    .my-votes-block__vote-form {
        padding-bottom: 16px;
        gap: 0;
    }
    .votes-page-switch-buttons {
        margin-bottom: 16px;
    }
    .my-votes-block__buttons-block {
        flex-direction: column;
        margin-top: 8px;
        gap: 8px;
    }
    .my-votes-block__edit-icon,
    .my-votes-block__delete-icon,
    .my-votes-block__show-vote-icon {
        display: none;
    }
    .my-votes-block__btn-block-edit {
        border: 1px solid #0084fe;
        border-radius: 4px;
        padding: 12px 18px 12px 18px;
        color: #FFFFFF;
        justify-content: center;
    }
    .my-votes-block__btn-block-new-tab {
        justify-content: center;
        border: 1px solid rgba(54, 59, 77, 1);
        border-radius: 4px;
        padding: 12px 18px 12px 18px;
        color: #FFFFFF;
    }
    .my-votes-block__btn-block-delete {
        justify-content: center;
        border: 1px solid #FF4970;
        border-radius: 4px;
        padding: 12px 29px 12px 29px;
        color: #FFFFFF;
    }
    .my-votes-block__btn-block-show {
        border: 1px solid#0084fe;
        border-radius: 4px;
        padding: 12px 29px 12px 29px;
        width: 100%;
       justify-content: center;
    }
    .my-votes-block__new-tab-label,
    .my-votes-block__edit-label,
    .my-votes-block__delete-label,
    .my-votes-block__show-label {
        font-size: 12px;
    }
}